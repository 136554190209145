import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from 'meta/routes'
import { Users } from 'meta/user'

import { useCycle } from 'client/store/assessment'
import { useUser } from 'client/store/user'
import { useCountryRouteParams } from 'client/hooks/useRouteParams'
import { ButtonSize, useButtonClassName } from 'client/components/Buttons/Button'
import Icon from 'client/components/Icon'

const Invite: React.FC = () => {
  const { t } = useTranslation()
  const user = useUser()
  const { countryIso } = useCountryRouteParams()
  const cycle = useCycle()
  const className = useButtonClassName({
    className: 'btn-invite',
    iconName: 'small-add',
    label: 'L',
    size: ButtonSize.s,
  })

  if (!Users.getRolesAllowedToEdit({ user, countryIso, cycle }).length) return null

  return (
    <Link className={className} to={Routes.CountryHomeSectionInvite.path.relative}>
      <Icon className="icon-sub icon-white" name="small-add" /> {t('userManagement.addUser')}
    </Link>
  )
}

export default Invite
